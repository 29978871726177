export const environment = {
  production: false,
  baseApiUrl: 'https://api-dev.fineandyou.com.br/api/',
  baseUrl: 'https://api-dev.fineandyou.com.br/',
  mevoURL: 'https://emr-homolog.nexodata.com.br/api/',
  openAi: {
    apiKey: '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',
    anamneseBot: 'asst_erMxpZaWwVlRn1WVSwP9xkhj',
  },
};
